.app {
  margin: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100;
}

a {
  color: black;
  text-decoration: none;
}

.searchandreset {
  position: relative;
}

.buttongroup {
  display: inline;
}

.search {
  padding: 10px;
  font-size: 20px;
  display: inline;
}

.resetbutton {
  padding: 10px;
  font-size: 20px;
  display: inline;
}

.listItem:hover {
  color: grey;
}

.list {
  padding: 0;
  list-style: none;
  text-align: center;
}

.listItem {
  margin-bottom: 20px;
  font-size: 25px;
  color: #444;
  font-weight: 300;
}

.response {
  white-space: pre-line;
}

table {
  width: 100%;
  margin: 50px 0px;
  border-spacing: 15px;
  color: #444;
}

tr {
  margin-bottom: 20px;
}

th {
  width: 250px;
  text-align: left;
  font-size: 20px;
}

td {
  width: 250px;
  font-size: 20px;
}